<template>
  <div class="pageContol listWrap templateList" id="pageContol">
    <div class="framePage">
      <div class="framePage-body">
        <div class="shell">
          <div class="l">
            <div class="tt">快速入口</div>
            <el-row class="row_1">
              <el-col :span="4">
                <div @click="goPages('/web/customProgramsRegistrationList')">
                  <img class="l_1" src="@/assets/operateHomeJdx/homeJdx_1.png" alt="">
                  <div class="l_2">创建报名</div>
                  <div class="l_3">快速创建报名信息，支持自定义信息项</div>
                </div>
              </el-col>
              <el-col :span="4">
                <div @click="goPages('/web/classListManage')">
                  <img class="l_1" src="@/assets/operateHomeJdx/homeJdx_2.png" alt="">
                  <div class="l_2">创建培训</div>
                  <div class="l_3">快速创建线上培训，支持导入和报名</div>
                </div>
              </el-col>
              <el-col :span="4">
                <div @click="goPages('/web/institutionalManagement/channelManagement/channelManagement')">
                  <img class="l_1" src="@/assets/operateHomeJdx/homeJdx_3.png" alt="">
                  <div class="l_2">创建渠道</div>
                  <div class="l_3">快速创建招生渠道，生成专属招生二维码</div>
                </div>
              </el-col>
              <el-col :span="4">
                <div @click="goPages('/web/institutionalManagement/operationalTools/storeConstruction')">
                  <img class="l_1" src="@/assets/operateHomeJdx/homeJdx_4.png" alt="">
                  <div class="l_2">首页装修</div>
                  <div class="l_3">设置宣传banner和报名的列表显示及顺序</div>
                </div>
              </el-col>
              <el-col :span="4">
                <div @click="goPages('/web/appraisalInstitution/examList')">
                  <img class="l_1" src="@/assets/operateHomeJdx/homeJdx_5.png" alt="">
                  <div class="l_2">创建考务</div>
                  <div class="l_3">创建考务信息，添加报名学员或批量导入学员</div>
                </div>
              </el-col>
              <el-col :span="4">
                <div @click="goPages('/web/appraisalInstitution/examinationRoomArrangement/arrange')">
                  <img class="l_1" src="@/assets/operateHomeJdx/homeJdx_6.png" alt="">
                  <div class="l_2">考场编排</div>
                  <div class="l_3">支持自动和手动编排考场，生成助考资料</div>
                </div>
              </el-col>
              <el-col :span="4">
                <div @click="goPages('/inkQuestionBank/QuestionBankManage/QuestionBankManage')">
                  <img class="l_1" src="@/assets/operateHomeJdx/homeJdx_7.png" alt="">
                  <div class="l_2">题库管理</div>
                  <div class="l_3">支持多种题型，自定义抽题策略</div>
                </div>
              </el-col>
              <el-col :span="4">
                <div @click="goPages('/inkQuestionBank/examinationPaperManage/tactics')">
                  <img class="l_1" src="@/assets/operateHomeJdx/homeJdx_8.png" alt="">
                  <div class="l_2">试卷管理</div>
                  <div class="l_3">支持编辑、复制试卷，可自定义试卷结构</div>
                </div>
              </el-col>
              <el-col :span="4">
                <div @click="goPages('/web/examination/onlineTest/onlineTestList')">
                  <img class="l_1" src="@/assets/operateHomeJdx/homeJdx_9.png" alt="">
                  <div class="l_2">创建考试</div>
                  <div class="l_3">快速创建、发布在线考试，支持随机分配试卷</div>
                </div>
              </el-col>
              <el-col :span="4">
                <div @click="goPages('/web/certificateManage/voucherPreparationTaskList')">
                  <img class="l_1" src="@/assets/operateHomeJdx/homeJdx_10.png" alt="">
                  <div class="l_2">制证任务</div>
                  <div class="l_3">自由管理发证机构，支持在线查询成绩及证书</div>
                </div>
              </el-col>
              <el-col :span="4">
                <div @click="goPages('/web/institutionalManagement/fileManagement/downloadInfo')">
                  <img class="l_1" src="@/assets/operateHomeJdx/homeJdx_11.png" alt="">
                  <div class="l_2">报名资料下载</div>
                  <div class="l_3">支持自定义文件命名，支持多种文件类型</div>
                </div>
              </el-col>
              <el-col :span="4">
                <div @click="goPages('/web/appraisalInstitution/testDataDownload/testDataDownloadList')">
                  <img class="l_1" src="@/assets/operateHomeJdx/homeJdx_12.png" alt="">
                  <div class="l_2">助考资料下载</div>
                  <div class="l_3">支持准考证、门贴、桌贴、签到表等资料下载</div>
                </div>
              </el-col>
            </el-row>
            <div class="tt">数据展示</div>
            <el-row class="row_2" :gutter="20">
              <el-col :span="8">
                <div class="col_1">
                  <div class="l_1">学员总数</div>
                  <div class="l_2">{{ dataShow.applyCount }}</div>
                </div>
              </el-col>
              <el-col :span="8">
                <div class="col_2">
                  <div class="l_1">今日报名人数</div>
                  <div class="l_2">{{ dataShow.applyDailyNum }}</div>
                </div>
              </el-col>
              <el-col :span="8">
                <div class="col_3">
                  <div class="l_1">参与培训人数</div>
                  <div class="l_2">{{ dataShow.applyTrainNum }}</div>
                </div>
              </el-col>
              <el-col :span="8">
                <div class="col_4">
                  <div class="l_1">参与考试人数</div>
                  <div class="l_2">{{ dataShow.applyExamNum }}</div>
                </div>
              </el-col>
              <el-col :span="8">
                <div class="col_5">
                  <div class="l_1">领取证书人数</div>
                  <div class="l_2">{{ dataShow.applyCertNum || 0 }}</div>
                </div>
              </el-col>
              <el-col :span="8">
                <div class="col_6">
                  <div class="l_1">招生老师人数</div>
                  <div class="l_2">{{ dataShow.channelNum }}</div>
                </div>
              </el-col>
            </el-row>
            <template v-if="earlyWarning.length">
              <div class="tt">预警管理</div>
              <div class="row_3">
                <div class="row_3_shell" v-for="(item, index) in earlyWarning" :key="index">
                  <img class="row_3_shell_1" src="@/assets/operateHomeJdx/homeJdx_13.png" alt="">
                  {{ item.warnContext }}
                </div>
              </div>
            </template>
          </div>
          <div class="r">
            <div class="tt">店铺信息</div>
            <div class="r_1">
              <div class="r_1_1">店铺名称：<span>{{ shopInfo.title }}</span></div>
              <div class="r_1_2">店铺链接：{{ shopInfo.link }} <span @click="copyUrl">复制链接</span> </div>
              <el-image class="r_1_3" style="width: 150px; height: 150px" :src="shopInfo.qrCodeURL" fit="fill"></el-image>
              <div class="r_1_4" @click="downloadQRcode">下载二维码</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <LoginRecommend />
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "operate_homeJdx",
  components: {

  },
  data() {
    return {
      // 店铺信息数据
      shopInfo: {
        title: "", // 店铺名称
        link: "", // url链接
        qrCodeURL: "", // 二维码-展示用
        qrCodeDownloadURL: "", // 二维码-下载用
      },
      // 数据展示数据
      dataShow: {
        applyCertNum: "", // 领取证书人数
        applyCount: "", // 学员总数
        applyDailyNum: "", // 今日报名人数
        applyExamNum: "", // 参与考试人数
        applyTrainNum: "", // 参与培训人数
        channelNum: "", // 招生老师数
      },
      // 预警管理
      earlyWarning: [],
    };
  },
  created() {
    this.getTheme();
    this.getDataShow();
    this.getEarlyWarning();
  },
  mounted() { },
  computed: {
    ...mapGetters({
      getMenu: "getMenu",
    }),
  },
  methods: {
    // 获取 - 店铺信息
    getTheme() {
      this.$post("/biz/ct/theme/getTheme", {}, 3000, false).then((res) => {
        if (res.status == 0) {
          this.shopInfo.title = res.data.title;
          this.shopInfo.link = res.data.link;
          this.shopInfo.qrCodeURL = res.data.qrCodeURL;
          this.shopInfo.downloadQRcodeUrl = res.data.qrCodeDownloadURL;
        }
      });
    },
    // 复制到剪贴板
    copyUrl() {
      let oInput = document.createElement("input");
      oInput.value = this.shopInfo.link;
      document.body.appendChild(oInput);
      oInput.select(); // 选择对象;
      document.execCommand("Copy"); // 执行浏览器复制命令
      this.$message({
        message: "已成功复制到剪切板",
        type: "success",
      });
      oInput.remove();
    },
    // 下载二维码
    downloadQRcode() {
      // let link = document.createElement("a");
      // link.style.display = "none";
      // link.href = this.downloadQRcodeUrl;
      // link.setAttribute("download", "报名二维码");
      // document.body.appendChild(link);
      // link.click();
      window.location.href = this.shopInfo.downloadQRcodeUrl;
    },
    // 获取 - 数据展示
    getDataShow() {
      this.$post("/biz/ct/home/dataShowTime", {}, 3000, false, 6).then((res) => {
        if (res.status == 0) {
          this.dataShow = { ...this.earlyWarning, ...res.data }
        }
      });
    },
    // 获取 - 预警管理
    getEarlyWarning() {
      this.$post("/biz/ct/home/warnPageList", {}, 3000, false, 6).then((res) => {
        if (res.status == 0) {
          this.earlyWarning = res?.data?.list ?? []
        }
      });
    },
    // 跳转页面
    goPages(url) {
      let urls = false;
      function recurrenceChildren(list) {
        for (let i = 0; i < list.length; i++) {
          if (list[i].children && list[i].children.length) {
            recurrenceChildren(list[i].children);
          } else {
            if (list[i].url == url) {
              urls = true;
            }
          }
        }
      }
      recurrenceChildren(this.getMenu);
      if (urls) {
        this.$router.push({
          path: url
        });
      } else {
        this.$message.error("您还没有开通此项权限，请联系客服开通");
      }
    },
  },
};
</script>
<style lang="less" scoped>
.framePage {
  .framePage-body {
    padding: 0 0.675rem;
    background: none;

    .shell {
      display: flex;
      height: 100%;

      .tt {
        margin: 10px 0;
        font-weight: 600;
      }

      .l {
        width: calc(100% - 300px);

        .el-row.row_1 {
          background: #fff;
          border-radius: 10px;

          .el-col {
            border: 1px solid #f9f9f9;
            text-align: center;
            padding: 20px 0;

            .l_1 {
              width: 40px;
              transition: transform .4s;
            }

            .l_2 {
              font-weight: 600;
              margin-top: 10px;
            }

            .l_3 {
              font-size: 12px;
              color: #999999;
              width: 120px;
              margin: auto;
              margin-top: 5px;
            }

            &:hover {
              cursor: pointer;

              .l_1 {
                transform: scale(1.3);
              }
            }
          }
        }

        .el-row.row_2 {
          background: #fff;
          border-radius: 10px;
          padding: 20px 0 0 0;
          margin-left: 0 !important;
          margin-right: 0 !important;

          .el-col {
            margin-bottom: 20px;

            >div {
              border-radius: 6px;
              padding: 20px;

              .l_2 {
                font-size: 24px;
                margin-top: 10px;
              }
            }
          }

          .col_1 {
            background: #F5FAFF;

            .l_2 {
              color: #43A1FF;
            }
          }

          .col_2 {
            background: #FFF9F0;

            .l_2 {
              color: #FFC171;
            }
          }

          .col_3 {
            background: #FFF5F0;

            .l_2 {
              color: #F99E6E;
            }
          }

          .col_4 {
            background: #EEFAF5;

            .l_2 {
              color: #57CF9F;
            }
          }

          .col_5 {
            background: #F2F1FD;

            .l_2 {
              color: #837BEC;
            }
          }

          .col_6 {
            background: #EFF4FF;

            .l_2 {
              color: #6893F8;
            }
          }
        }

        .row_3 {
          .row_3_shell {
            background: #FFF1F1;
            border: 1px solid #FF7474;
            color: #FF8787;
            padding: 6px 8px;
            margin-bottom: 10px;
            border-radius: 6px;
            display: flex;

            .row_3_shell_1 {
              width: 14px;
              margin-right: 8px;
            }
          }
        }
      }

      .r {
        width: 280px;
        margin-left: 20px;

        .r_1 {
          background: #fff;
          border-radius: 10px;
          padding: 20px;

          .r_1_1 {
            span {
              font-weight: 600;
              margin-bottom: 10px;
            }
          }

          .r_1_2 {
            span {
              font-size: 12px;
              color: #43A1FF;
              cursor: pointer;
            }
          }

          .r_1_3 {
            display: block;
            margin: auto;
          }

          .r_1_4 {
            font-size: 12px;
            color: #43A1FF;
            text-align: center;
            cursor: pointer;
          }
        }
      }
    }
  }
}
</style>