import { render, staticRenderFns } from "./homeJdx.vue?vue&type=template&id=c3215cea&scoped=true"
import script from "./homeJdx.vue?vue&type=script&lang=js"
export * from "./homeJdx.vue?vue&type=script&lang=js"
import style0 from "./homeJdx.vue?vue&type=style&index=0&id=c3215cea&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c3215cea",
  null
  
)

export default component.exports